import { ApplicationController } from 'stimulus-use'
import { turboSubmitForm } from '~/utils/form_turbo_submit.js'
import arrowUp from '~/../assets/images/pictos/arrow-up.svg';
import arrowDown from '~/../assets/images/pictos/arrow-down.svg';

const orderingInputLeftIcons = {
  'arrow-up': arrowUp,
  'arrow-down': arrowDown
}

export default class extends ApplicationController {
  static targets = ["resetChip"]

  displayResetChip() {
    if (this.checkedFilters()) {
        this.resetChipTarget.classList.remove('hidden')
      } else {
        this.resetChipTarget.classList.add('hidden')
      }
  }

  checkedFilters() {
    const checkboxInputs = this.element.querySelectorAll("input[type='checkbox']")
    const areChecked = Array.from(checkboxInputs).some(item => item.checked == true)

     return areChecked
  }

  formSubmit() {
    const form = this.element

    turboSubmitForm(form)
  }

  reset() {
    this.resetCheckboxes()
    this.resetSearchInputs()

  }

  resetCheckboxes() {
    const checkboxInputs = this.element.querySelectorAll("input[type='checkbox']")
    checkboxInputs.forEach(item => {
      item.checked = false
      item.dispatchEvent(new Event('change', { bubbles: true, composed: true }))

    })
  }

  resetSearchInputs() {
    const searchInputs = this.element.querySelectorAll("input[type='search']")
    searchInputs.forEach(item => {
      item.value = ""
    })
  }

  changeLeftIcon(event) {
    const pictoName = event.currentTarget.dataset.leftIcon
    const newPictoUrl = orderingInputLeftIcons[pictoName]
    const svgContainer = this.element.querySelector('svg')

    fetch(newPictoUrl)
      .then(response => response.text())
      .then(data => {
              svgContainer.innerHTML = data
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}
