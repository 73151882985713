import { Controller } from 'stimulus'

const lifetime = 3000

export default class extends Controller {

  connect() {
    setTimeout(() => {
      this.element.remove()
    }, lifetime)
  }

  close(event) {
    event.preventDefault();
   this.element.remove()
  }
}
